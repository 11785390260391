// src/apis/baysApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const fetchBays = async (currentTime) => {
  try {
    const response = await axios.get(`${baseURL}/api/bays`, {
      params: {
        current_time: currentTime
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw error.response.data;
  }
};