import { Box, VStack, HStack, Text, Button, Input, IconButton } from "@chakra-ui/react";
import { FaUser, FaCreditCard, FaTv, FaTasks, FaCog } from "react-icons/fa";
import { useState, useEffect } from "react";
import { fetchUserProfile } from '../apis/userProfileApi';

const PersonalHeader = () => {
  const [activeSection, setActiveSection] = useState("profile");
  const [editMode, setEditMode] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await fetchUserProfile();
        const user = userProfile.data[0];
        setFormData({
          firstName: user.firstname,
          lastName: user.lastname,
          email: user.email,
        });
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchData();
  }, []);

  const handleEditClick = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sections = {
    profile: (
      <Box h="100%" overflowY="auto" p={4}>
        <Text fontSize="xl" fontWeight="bold">Personal Info</Text>
        <VStack spacing={4} alignItems="flex-start">
          <Box w="100%">
            <Text fontSize="sm" fontWeight="bold">First Name</Text>
            <HStack spacing={4} w="100%">
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                size="sm"
                isDisabled={!editMode.firstName}
                flex="1"
                _focus={{ boxShadow: "none", outline: "none" }}
              />
              <Button size="xs" onClick={() => handleEditClick("firstName")}>
                {editMode.firstName ? "Save" : "Update"}
              </Button>
            </HStack>
          </Box>
          <Box w="100%">
            <Text fontSize="sm" fontWeight="bold">Last Name</Text>
            <HStack spacing={4} w="100%">
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                size="sm"
                isDisabled={!editMode.lastName}
                flex="1"
                _focus={{ boxShadow: "none", outline: "none" }}
              />
              <Button size="xs" onClick={() => handleEditClick("lastName")}>
                {editMode.lastName ? "Save" : "Update"}
              </Button>
            </HStack>
          </Box>
          <Box w="100%">
            <Text fontSize="sm" fontWeight="bold">Email</Text>
            <HStack spacing={4} w="100%">
              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                size="sm"
                isDisabled={!editMode.email}
                flex="1"
                _focus={{ boxShadow: "none", outline: "none" }}
              />
              <Button size="xs" onClick={() => handleEditClick("email")}>
                {editMode.email ? "Save" : "Update"}
              </Button>
            </HStack>
          </Box>
        </VStack>
      </Box>
    ),
    payment: (
      <Box h="100%" overflowY="auto" p={4}>
        <Text fontSize="xl" fontWeight="bold">Payment Info</Text>
      </Box>
    ),
    subscription: (
      <Box h="100%" overflowY="auto" p={4}>
        <Text fontSize="xl" fontWeight="bold">Your Subscription</Text>
      </Box>
    ),
    privacy: (
      <Box h="100%" overflowY="auto" p={4}>
        <Text fontSize="xl" fontWeight="bold">Privacy Settings</Text>
      </Box>
    ),
    settings: (
      <Box h="100%" overflowY="auto" p={4}>
        <Text fontSize="xl" fontWeight="bold">Account Settings</Text>
      </Box>
    ),
  };

  return (
    <Box p={4}>
      <Box bg="white" w={{ base: "95%", md: "540px" }} h={{ base: "auto", md: "420px" }} mt="2.5%" boxShadow="sm" position="relative">
        <HStack spacing={2} align="start">
          <VStack spacing={1} bg="#68D391" boxShadow="md" p={4}>
            <IconButton icon={<FaUser />} onClick={() => setActiveSection("profile")} colorScheme={activeSection === "profile" ? "teal" : "whiteAlpha"} />
            <IconButton icon={<FaCreditCard />} onClick={() => setActiveSection("payment")} colorScheme={activeSection === "payment" ? "teal" : "whiteAlpha"} />
            <IconButton icon={<FaTv />} onClick={() => setActiveSection("subscription")} colorScheme={activeSection === "subscription" ? "teal" : "whiteAlpha"} />
            <IconButton icon={<FaTasks />} onClick={() => setActiveSection("privacy")} colorScheme={activeSection === "privacy" ? "teal" : "whiteAlpha"} />
            <IconButton icon={<FaCog />} onClick={() => setActiveSection("settings")} colorScheme={activeSection === "settings" ? "teal" : "whiteAlpha"} />
          </VStack>
          <Box w="100%" h={{ base: "auto", md: "100%" }} overflowY="auto">
            {sections[activeSection]}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default PersonalHeader;